import axios from "axios";

const helper = {
  baseUrl: function () {
    //const URL = "https://api.meddtoday.com/"
    // const URL = "https://www.kareersity.org/";
    //const URL = "http://13.127.129.142:4000/";
    const URL = "https://www.dev.backend.kareersity.com/";
    //    const URL = "https://www.backend.kareersity.com/";
    // const URL = "http://localhost:4000/";
    return URL;
  },

  postFormData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .post(url, data, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  postData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .post(url, data, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  deletewithData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .delete(url, data, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteData: async function (url) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .delete(url, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  patchData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .patch(url, data, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  pathFormData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .patch(url, data, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  formData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };

    return await axios
      .post(url, data, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        Authorization: localStorage.getItem("adminOAuth"),
      },
    };
    return await axios.get(url, axiosConfig).then((res) => {
      if (res) {
        return res;
      }
    });
  },
  queryData: async function (url, query) {
    return await axios.get(url, query).then((res) => {
      if (res) {
        return res;
      }
    });
  },
};

export default helper;
